import { HTMLAttributes } from 'react'
import { Container } from '@Styles/grid.styled'
import Head from 'next/head'
import Layout from '@Components/Website/Global/Layout'

interface SimpleLayoutProps extends HTMLAttributes<HTMLDivElement> {
  title: string
}

const SimpleLayout: React.FC<SimpleLayoutProps> = (props) => {
  const { children } = props
  return (
    <>
      <Head>{props.title && <title>{props.title} | Banto</title>}</Head>
      <Layout noShadow>
        <Container>{children}</Container>
      </Layout>
    </>
  )
}

export default SimpleLayout
