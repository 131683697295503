import styled, { css } from 'styled-components'
import tw from 'twin.macro'
import { getTextColor } from '../../../../styles/global.styled'
import { TextColor } from '../../../services/types'

const headingStyle = css<{ flex?: boolean; color: TextColor }>`
  ${tw`font-semibold font-heading relative text-greyDarker`}
  line-height: 1.1;
  ${({ flex }) => flex && tw`flex items-center`}
  ${({ color }) => color && getTextColor(color)}

  > span {
    ${tw`text-greyLight`}
  }
`

export const H1 = styled.h1<{ flex?: boolean }>`
  ${headingStyle}
  ${tw`text-3xl`}
`

export const H2 = styled.h2<{ flex?: boolean }>`
  ${headingStyle}
  ${tw`text-xl`}
`

export const H3 = styled.h3<{ flex?: boolean }>`
  ${headingStyle}
  ${tw`text-xl`}
`

export const H4 = styled.h4<{ flex?: boolean }>`
  ${headingStyle}
  ${tw`text-lg`}
`

export const H5 = styled.h5<{ flex?: boolean }>`
  ${headingStyle}
  ${tw`text-base`}
`

export const H6 = styled.h6<{ flex?: boolean }>`
  ${headingStyle}
  ${tw`text-sm`}
`

export const Message = styled.span`
  top: -85%;
  ${tw`absolute left-28 text-sm text-grey block bg-ghostWhite font-body font-normal px-4 pt-1.5 pb-1 rounded-full whitespace-nowrap`}

  &:after {
    ${tw`absolute w-0 h-0 -bottom-1 left-5 border-8 border-solid border-ghostWhite origin-center`}
    content: '';
    transform: rotate(-18deg);
    border-color: transparent transparent #FAFBFC #FAFBFC;
  }
`
