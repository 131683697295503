import { HTMLAttributes, ReactElement, ReactNode } from 'react'
import { TextColor } from '../../../services/types'
import * as S from './Title.styled'

interface TitleProps extends HTMLAttributes<HTMLHeadingElement> {
  level?: 1 | 2 | 3 | 4 | 5 | 6
  flex?: boolean
  children?: ReactNode
  message?: string
  color?: TextColor
}

const Title = (props: TitleProps) => {
  const { level, message, children, color, flex, ...rest } = props
  let component: ReactElement
  switch (level) {
    case 1:
      component = (
        <S.H1 color={color} flex={flex} {...rest}>
          {message && <S.Message>{message}</S.Message>}
          {children}
        </S.H1>
      )
      break
    case 2:
      component = (
        <S.H2 color={color} flex={flex} {...rest}>
          {message && <S.Message>{message}</S.Message>}
          {children}
        </S.H2>
      )
      break
    case 3:
      component = (
        <S.H3 color={color} flex={flex} {...rest}>
          {message && <S.Message>{message}</S.Message>}
          {children}
        </S.H3>
      )
      break
    case 4:
      component = (
        <S.H4 color={color} flex={flex} {...rest}>
          {message && <S.Message>{message}</S.Message>}
          {children}
        </S.H4>
      )
      break
    case 5:
      component = (
        <S.H5 color={color} flex={flex} {...rest}>
          {message && <S.Message>{message}</S.Message>}
          {children}
        </S.H5>
      )
      break
    case 6:
      component = (
        <S.H6 color={color} flex={flex} {...rest}>
          {message && <S.Message>{message}</S.Message>}
          {children}
        </S.H6>
      )
      break
  }

  return <>{component}</>
}

Title.defaultProps = {
  level: 1,
  color: 'greyDarker',
}

export default Title
